import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { COLOR_CORAL } from '../utils/constants';
import arrowWhite from '../images/arrowWhite.png';

const useStyles = makeStyles(() => ({
  input: {
    border: '1px solid black',
    width: '100%',
    height: 40,
    maxWidth: 600,
    marginTop: 20,
    paddingLeft: 15,
    outline: 'none',
    '@media (max-width:600px)': {
      maxWidth: 300,
    },
  },
  message: {
    border: '1px solid black',
    width: '100%',
    maxWidth: 600,
    paddingLeft: 15,
    paddingTop: 15,
    resize: 'none',
    outline: 'none',
    '@media (max-width:600px)': {
      maxWidth: 300,
    },
  },
  inputBtn: {
    marginTop: 20,
    border: '1px solid',
    display: 'inline-block',
    padding: '6px 12px',
    cursor: 'pointer',
    backgroundColor: '#fff',
    fontSize: 12,
  },
  submitBtn: {
    cursor: 'pointer',
    fontSize: 12,
    color: 'white',
    padding: '8px 8px 8px 18px',
    border: 'none',
    backgroundColor: COLOR_CORAL,
    '-webkit-appearance': 'none',
    '&:hover': {
      backgroundColor: COLOR_CORAL,
      boxShadow: 'none',
    },
  },
  containerSubmit: {
    cursor: 'pointer',
    backgroundColor: COLOR_CORAL,
    display: 'flex',
    height: 40,
    marginTop: '40px',
    alignItems: 'center',
    width: 'max-content',
    paddingRight: 18,
  },
  iconSubmit: {
    height: 16,
    width: 10,
  },
}));

// This component is a quickfix for marketing campaigne, please create new dynamic form if needed.
const FormCV = () => {
  const [state, setState] = useState({
    fullName: '',
    email: '',
    message: '',
  });
  const classes = useStyles();

  const handleChange = ({ target }) => {
    const { value, id } = target;
    setState({
      ...state,
      [id]: value,
    });
  };

  return (
    <form
      name="workSecond"
      method="POST"
      netlify-honeypot="bot-field"
      data-netlify="true"
      // action="/work-with-us"
    >
      <input type="hidden" name="form-name" value="work" />
      <input
        id="fullName"
        name="fullName"
        type="text"
        placeholder="Name"
        onChange={handleChange}
        className={classes.input}
        value={state.fullName}
      />
      <br />
      <input
        id="email"
        name="email"
        type="email"
        placeholder="Email"
        onChange={handleChange}
        className={classes.input}
        value={state.email}
      />
      <br />
      {/* TODO: If wanted add cv file, add this part and check file size for it */}
      {/* <label htmlFor="file-upload" className={classes.inputBtn}>
        Add CV
      </label>
      <span style={{ paddingLeft: 10 }}>{cv}</span>
      <input id="file-upload" type="file" className={classes.seeBaseCSS} /> */}
      <br />
      <textarea
        id="message"
        name="message"
        rows="12"
        placeholder="Message"
        className={classes.message}
        onChange={handleChange}
        value={state.message}
      />
      <br />
      <div className={classes.containerSubmit}>
        <input type="submit" value="Send" className={classes.submitBtn} />
        <img src={arrowWhite} className={classes.iconSubmit} alt="vow arrow" />
      </div>
    </form>
  );
};

export default FormCV;
